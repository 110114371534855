











































import { Employee } from "@/models/entities/employee.interface";
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

const CosmopolSubstituteItem = () =>
  import(
    /* webpackChunkName: "CosmopolSubstituteItem" */ "./CosmopolSubstituteItem.vue"
  );

@Component({
  components: {
    "cosmopol-substitute-item": CosmopolSubstituteItem,
  },
})
export default class CosmopolSubstitutesAutocomplete extends Vue {
  @PropSync("selected", {
    required: false,
    default: null,
    type: [String, Number],
  })
  private _selected: string | number;
  @Prop({ required: false, default: false, type: Boolean })
  private offsetX: boolean;
  @Prop({ required: false, default: null, type: Array })
  private employees: Employee[];

  private substitutesArr = [];
  private searchTimeout: number = null;
  private showMenu = false;
  private id = uuidv4();

  private created(): void {
    if (this.employees) {
      const employeesDict = this.employees.reduce(
        (result, item) => ({
          ...result,
          [item["employeeClassificationName"]]: [
            ...(result[item["employeeClassificationName"]] || []),
            item,
          ],
        }),
        {}
      );
      const keys = Object.keys(employeesDict).sort().reverse();
      for (let idx = 0; idx < keys.length; idx++) {
        const header = {
          header: `${keys[idx]} (${employeesDict[keys[idx]].length})`,
        };
        this.substitutesArr.push(header);
        this.substitutesArr.push(...employeesDict[keys[idx]]);
        if (idx != keys.length - 1) {
          this.substitutesArr.push({ divider: true });
        }
      }
    }
  }

  private mounted(): void {
    this.showMenu = true;
  }

  onAutocompleteInput(item: string): void {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this._selected = item;
      let value = item;
      if (value) {
        const idx = value.indexOf("_");
        if (idx > -1) value = value.substr(0, idx);
      }
      this.$emit("on-change", Number(value));
    }, 200);
  }

  private itemValue(value: Employee): string {
    return `${value.employeeId}_${value.employeeClassificationName}`;
  }

  private filter(item: unknown, queryText: string, itemText: string): boolean {
    if (item["header"] || item["divider"]) {
      return true;
    }
    return (
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }
}
